import { QueryClient, dehydrate } from '@tanstack/react-query';
import { GetStaticPaths, GetStaticProps } from 'next';
import { ReactElement } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { minutes, months } from '~/shared/utils/helpers';
import DynamicLayout from '~/templates/layouts';
import DynamicPage, { DynamicPageProps } from '~/templates/pages';
import { prefetchStaticData } from '~/templates/pages/utils';

export const getStaticProps: GetStaticProps<DynamicPageProps, PageParams> = async (context) => {
    const { params, locale } = context;
    const queryClient = new QueryClient();

    // Fetch frame, page, translations
    const { page, notFound } = await prefetchStaticData({
        queryClient,
        locale,
        slug: params?.slug,
    });
    const dehydratedState = dehydrate(queryClient);

    // Clear the cache to avoid high memory consumption on server
    queryClient.clear();

    if (page?.type === UmbracoTypes.PageTypes.IPRedirect) {
        const { destination, permanent } = page;
        return {
            revalidate: permanent ? months(1, true) : minutes(5, true),
            redirect: { destination, permanent },
        };
    }

    if (notFound) {
        return { revalidate: minutes(5, true), notFound: true };
    }

    return {
        revalidate: minutes(1, true),
        props: {
            dehydratedState,
        },
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    return {
        paths: [],
        fallback: 'blocking',
    };
};

export default function SlugPage() {
    return <DynamicPage />;
}

SlugPage.getLayout = function getLayout(page: ReactElement) {
    return <DynamicLayout>{page}</DynamicLayout>;
};

type PageParams = {
    slug: string[];
};
